html,
body {
  overscroll-behavior-x: none;
}

body {
  margin: 0;
  padding: 0;
  font-variant-ligatures: none;
  font-family: "Noto", sans-serif;
  font-weight: 400;
  line-height: 150%;
}

body.modal-open {
  overflow: hidden;
}

button {
  font-family: "Noto", sans-serif;
  background: none;
  border: none;
}

p,
h1,
h2,
h3,
ul {
  margin: 0;
  padding: 0;
}

textarea:focus, input:focus, button:focus {
  outline: none;
}


*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body.modal-open {
  overflow: hidden;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}


textarea:focus, input:focus, button:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    
}

input:-webkit-autofill{
  -webkit-text-fill-color: #26357A !important;
  -webkit-font-weight: normal;
}

.bodybg {
  background: #F8FAFC;
}

@font-face {
  font-family: "Noto";
  src: url(assets/fonts/NotoSans-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto";
  src: url(assets/fonts/NotoSans-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto";
  src: url(assets/fonts/NotoSans-SemiBold.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto";
  src: url(assets/fonts/NotoSans-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto-Thin.woff2) format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto-Light.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto-Black.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
